import React from "react"
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import {
    FirstScreen,
    Advantages,
    SizeOfRates,
    StagesOfWork,
    GrowthChart
} from '../components/GreenTariff'
// global
import { FormOrder, Breadcrumbs } from '../components/Global'
// context
import YAMLGlobal from "../content/ua/global.yaml"
import YAMLGreen from "../content/ua/green.yaml"
import { graphql, useStaticQuery } from "gatsby"

const GreenTariffPage = ({location}) => {
    const data = useStaticQuery(graphql`
      query {
          seo: directusGreenTariffSeo {
              title_ua
              description_ua
          }
          mainInfo: directusGreenTariffMain {
              description_ua
              main_image {
                  localFile {
                      childImageSharp {
                          fluid(maxWidth: 825) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
              value_of_bets_image {
                  localFile {
                      childImageSharp {
                          fluid(maxWidth: 892) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
              graphics_title_ua
              graphics_top_ua
              graphics_right_ua
          }
          advantages: allDirectusGreenTarifAdvantage(sort: {fields: sort}) {
              edges {
                  node {
                      id
                      icon {
                          localFile {
                              publicURL
                          }
                      }
                      description_ua
                  }
              }
          }
          steps: allDirectusGreenTarifStepsWork(sort: {fields: sort}) {
              edges {
                  node {
                      id
                      title_ua
                      description_ua
                      image {
                          localFile {
                              childImageSharp {
                                  fluid(maxWidth: 618) {
                                      ...GatsbyImageSharpFluid
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
    `)

    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '/green-tariff',
            title: YAMLGlobal.breadCrumbs.greenTariff
        }
    ]
    
    const greenTariffLocal = {
        langBase: "/ua",
        YAMLGlobal,
        YAMLContent: YAMLGreen
    }
    
    return(
        <Layout local={greenTariffLocal} location={location}>
            <SEO title={data.seo.title_ua} description={data.seo.description_ua} lang='ua' />
            <Breadcrumbs list={list}/>
            <FirstScreen
                description={data.mainInfo.description_ua}
                image={data.mainInfo.main_image.localFile?.childImageSharp.fluid}
            />
            <Advantages
                data={data.advantages.edges}
            />
            <SizeOfRates
              image={data.mainInfo.value_of_bets_image.localFile?.childImageSharp.fluid}
            />
            <StagesOfWork stages={data.steps.edges}/>
            <GrowthChart
              title={data.mainInfo.graphics_title_ua}
              topText={data.mainInfo.graphics_top_ua}
              rightText={data.mainInfo.graphics_right_ua}
            />
            <FormOrder/>
        </Layout>
    )
}

export default GreenTariffPage;